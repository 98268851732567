import type {
  IDashboardResultTopLesseesItem,
  IDashboardResultTopLessorsItem,
} from '~/composables/useAPI/useApiDashboard'
import { createDashboardReportURI } from '../utils'
import { DashboardQuery } from '@dashboard'
import { tv } from 'tailwind-variants'
import {
  simpleTableVariant,
  createColumnHelper,
} from '@ui/components/SimpleTable'

export type LessorOrLesseeItem =
  | IDashboardResultTopLessorsItem
  | IDashboardResultTopLesseesItem

export const useLandlordsWidgetTable = (
  by: MaybeRefOrGetter<
    | DashboardQuery.TOP_LESSEES
    | DashboardQuery.TOP_LESSEES_BY_RENT
    | DashboardQuery.TOP_LESSORS
    | DashboardQuery.TOP_LESSORS_BY_RENT
  >,
  results: ComputedRef<
    IDashboardResultTopLesseesItem[] | IDashboardResultTopLessorsItem[]
  >,
) => {
  const { isLandlord } = useAuth()
  const { unitSize, currentDivision } = useDivisions()
  const companyId = getCurrentDivisionId()

  const createCurrency = (amount: number): string => {
    //TODO: fix type error
    const localization = currentDivision.value.localization

    const locale = localization.locale ?? 'en-AU'
    const currency = localization.currency?.id || 'AUD'
    const currencyDisplay = 'narrowSymbol'

    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      currencyDisplay,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount)
  }

  // COLUMNS

  const columnHelperLessor =
    createColumnHelper<IDashboardResultTopLessorsItem>()
  const columnHelperLessee =
    createColumnHelper<IDashboardResultTopLesseesItem>()
  const columHelperLesseeOrLessor = createColumnHelper<
    IDashboardResultTopLessorsItem | IDashboardResultTopLesseesItem
  >()

  const columns = computed(() => {
    const isByRent =
      toValue(by) === DashboardQuery.TOP_LESSEES_BY_RENT ||
      toValue(by) === DashboardQuery.TOP_LESSORS_BY_RENT

    const cols = []

    if (isLandlord.value) {
      cols.push(
        columnHelperLessee('tenant', {
          header: 'Tenant',
        }),
      )
    } else {
      cols.push(
        columnHelperLessor('landlord', {
          header: 'Landlord',
        }),
      )
    }

    if (isByRent) {
      cols.push(
        columHelperLesseeOrLessor('totalCurrentRent', {
          header: 'Rent',
          footer: createCurrency(
            results.value.reduce(
              (acc, item) => acc + (item as any).totalCurrentRent,
              0,
            ),
          ),
          cell: (value) => {
            const totalRent = value as number
            if (totalRent === undefined) return
            return createCurrency(totalRent)
          },
        }),
      )
    } else {
      cols.push(
        columHelperLesseeOrLessor('totalArea', {
          header: `Area (${unitSize.value?.text})`,
          footer: new Intl.NumberFormat().format(
            results.value.reduce(
              (acc, item) => acc + (item as any).totalArea,
              0,
            ),
          ),
          cell: (value) => {
            const totalArea = value as number
            if (totalArea === undefined) return
            return new Intl.NumberFormat().format(totalArea)
          },
        }),
      )
    }

    cols.push(
      columHelperLesseeOrLessor('percentage', {
        header: 'Portfolio Composition',
        // footer: results.value.reduce((acc, item) => acc + item.percentage, 0),
        footer: '100%',
        cell: (value) => {
          const percentage = value
          if (percentage === undefined) return
          return `${new Intl.NumberFormat().format(percentage * 100)}%`
        },
      }),
    )

    return cols
  })

  // ROW CLICK
  const router = useRouter()
  const onClickRow = (obj: LessorOrLesseeItem, templateId?: string) => {
    //TODO: get the templateId
    const path = createDashboardReportURI(companyId, obj.link, templateId)
    if (path) {
      router.push(path)
    }
  }

  // VARIANT
  const variant = tv({
    extend: simpleTableVariant,
    slots: {
      theadTr: 'bg-gray-800',
      theadTh: [
        '!text-xs',
        '[&:nth-last-child(1)]:text-center',
        '[&:nth-last-child(1)]:!py-2',
        '[&:nth-last-child(1)]:!px-1',
        '[&:nth-last-child(1)>span]:inline',
        '[&:nth-last-child(2)]:text-center',
        '[&:nth-last-child(2)]:!py-2',
        '[&:nth-last-child(2)]:!p-1',
        '[&:nth-last-child(2)>span]:inline',
      ],
      tbodyTd: [
        '!text-xs',
        '[&:nth-last-child(1)]:text-center',
        '[&:nth-last-child(1)]:!p-0',
        '[&:nth-last-child(1)]:!px-1',
        '[&:nth-last-child(1)>span]:inline',
        '[&:nth-last-child(2)]:text-center',
        '[&:nth-last-child(2)]:!p-0',
        '[&:nth-last-child(2)]:!px-1',
        '[&:nth-last-child(2)>span]:inline',
      ],
      tfootTr: 'bg-gray-800',
      tfootTh: [
        '[&:nth-last-child(1)]:text-center',
        '[&:nth-last-child(1)]:!py-2',
        '[&:nth-last-child(1)]:!px-1',
        '[&:nth-last-child(1)>span]:inline',
        '[&:nth-last-child(2)]:text-center',
        '[&:nth-last-child(2)]:!py-2',
        '[&:nth-last-child(2)]:!p-1',
        '[&:nth-last-child(2)>span]:inline',
      ],
    },
    variants: {
      hoverable: {
        true: {
          tbodyTr: 'hover:text-white',
        },
      },
      dividedRows: {
        true: {
          thead: 'border-b-gray-750',
          tbody: 'divide-gray-750',
          tfoot: 'border-t-gray-750',
        },
      },
      stripedRows: {
        false: {
          tbodyTr: 'bg-gray-800',
        },
      },
    },
  })

  return { columns, variant, onClickRow }
}
