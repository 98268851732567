import type { SimpleTableColumn } from '@ui/components/SimpleTable/types'

export function createColumnHelper<
  TData extends Record<string, any> = Record<string, any>,
>(): <ID extends keyof TData | string>(
  id: ID,
  props: {
    header: string
    footer?: any
    cell?: (
      value: ID extends keyof TData ? TData[ID] : any,
      context: { result: TData },
    ) => any
  },
) => SimpleTableColumn {
  return (id, props) => {
    return {
      id,
      ...props,
    } as SimpleTableColumn
  }
}
